import { Component, ComponentRef, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TableSchema } from "../../table-list/table-list.component";
import { ZxSourceComponent } from "../../zx-source/zx-source.component";
import { TranslateService } from "@ngx-translate/core";
import { RecoveryState, Source } from "src/app/models/shared";
import { ZxClusterComponent } from "../../zx-cluster/zx-cluster.component";
import { SourcesService } from "src/app/pages/sources/sources.service";
import { combineLatest, firstValueFrom, Observable, of } from "rxjs";
import { TargetBroadcasterSelectionComponent } from "../../target-broadcaster-selection/target-broadcaster-selection.component";
import { SpinnerAnimationInlineComponent } from "../../spinner-animation-inline/spinner-animation-inline.component";
import { ClustersService } from "src/app/pages/clusters/clusters.service";
import { Cluster } from "src/app/pages/clusters/cluster";
import { ChannelsService } from "src/app/pages/channels/channels.service";
import {
    AdaptiveChannel,
    DeliveryChannel,
    FailoverChannel,
    TargetApiType,
    TargetObjectType,
    TargetPidMappingTypes
} from "src/app/pages/channels/channel";
import { DialogObjectComponent } from "../multiple-confirm-dialog/dialog-object/dialog-object.component";
import { TargetsService } from "src/app/pages/targets/targets.service";
import { ZxTargetComponent } from "../../zx-target/zx-target.component";
import { ZxTargetChannelColumnComponent } from "../../table-list/tables-components/zx-target-channel-column/zx-target-channel-column.component";
import { assignComponentsTargetChannelsColumnAdapter } from "../../table-list/tables-components/zx-target-channel-column/zx-target-channels-column.table-adapter";
import {
    SourcePreferenceSelectionValues,
    ZxDeliveryChannelSourceSelectComponent
} from "../../zx-delivery-channel-source-select/zx-delivery-channel-source-select.component";

type SupportedChannelTypes = "adaptive_channel" | "delivery_channel" | "failover_channel";
type SupportedObjectTypes = "source" | SupportedChannelTypes | TargetApiType;

@Component({
    selector: "app-disaster-recovery-dialog",
    templateUrl: "./disaster-recovery-dialog.component.html"
})
export class DisasterRecoveryDialogComponent implements OnInit {
    @Input() objects: {
        id: number;
        type: SupportedObjectTypes;
    }[];

    private supportedChannelTypes = ["adaptive_channel", "delivery_channel", "failover_channel"];
    private supportedTargetTypes = [
        "pull",
        "http",
        "push",
        "rtmp",
        "udp_rtp",
        "rist",
        "srt",
        "ndi",
        "mediaconnect",
        "cdi",
        "jpegxs",
        "medialive_http",
        "entitlement"
    ];
    private broadcastersOnlySourceTypes = ["monitor_only", "udp", "file", "srt", "rist", "demux", "multiplex"]; // target broadcaster selection won't display "prefer primary broadcasters" for these source types

    loading = true;
    disableInputs = false;

    sources: Source[] = [];
    channels: (DeliveryChannel | AdaptiveChannel | FailoverChannel)[] = [];
    targets: TargetObjectType[] = [];

    selectedSourcesRows: Array<Source> = [];
    selectedChannelRows: Array<DeliveryChannel | AdaptiveChannel | FailoverChannel> = [];
    selectedTargetRows: Array<TargetObjectType> = [];

    constructor(
        public activeModal: NgbActiveModal,
        private translateService: TranslateService,
        private ss: SourcesService,
        private cs: ClustersService,
        private channelsService: ChannelsService,
        private ts: TargetsService
    ) {}

    async ngOnInit() {
        this.loading = true;

        const promises = [];

        const sourceObjects = this.objects.filter(o => o.type === "source");
        promises.push(this.handleSourceObjects(sourceObjects));

        const channelObjects = this.objects.filter(o => this.supportedChannelTypes.includes(o.type));
        promises.push(this.handleChannelObjects(channelObjects as any));

        const targetObjectType = this.objects.filter(o => this.supportedTargetTypes.includes(o.type));
        promises.push(this.handleTargetObjects(targetObjectType as any));

        await Promise.all(promises);
        this.resetItems();
        this.selectAll();
        this.refreshTable();
        this.loading = false;
    }

    private fetchSources$(sourceIds: number[]): Observable<Source[]> {
        return combineLatest(sourceIds.map(id => this.ss.refreshSource(id, true)));
    }

    private fetchClusters$(clusterIds: number[]): Observable<Cluster[]> {
        // get the "to cluster" clusters
        if (clusterIds.length === 0) return of([]);
        return combineLatest(clusterIds.map(id => this.cs.refreshCluster(id, true)));
    }

    private fetchChannels(channelObjects: { id: number; type: SupportedChannelTypes }[]) {
        const promises = [];
        for (const object of channelObjects) {
            let promise;
            switch (object.type) {
                case "adaptive_channel":
                    promise = this.channelsService.getAdaptiveChannel(object.id);
                    break;
                case "delivery_channel":
                    promise = this.channelsService.getDeliveryChannel(object.id);
                    break;
                case "failover_channel":
                    promise = this.channelsService.getFailoverChannel(object.id);
                    break;
            }
            promises.push(promise);
        }
        return Promise.all(promises);
    }

    private async handleSourceObjects(sourceObjects) {
        if (sourceObjects.length === 0) return of([]);
        const sources = await firstValueFrom(this.fetchSources$(sourceObjects.map(o => o.id)));
        const clusters = await firstValueFrom(
            this.fetchClusters$(
                sources.flatMap(source =>
                    this.ss.getDisasterRecoveryState(source) === RecoveryState.alternative
                        ? [source.primary_broadcaster_cluster_id]
                        : []
                )
            )
        );
        sources.map(source => {
            source._frontData.primaryCluster = clusters.find(c => c.id === source.primary_broadcaster_cluster_id);
        });
        this.sources = sources.map(s => {
            return { ...s };
        });
    }

    private async handleChannelObjects(channelObjects: { id: number; type: SupportedChannelTypes }[]) {
        const channels = await this.fetchChannels(channelObjects);

        const clusters = await firstValueFrom(
            this.fetchClusters$(
                channels.flatMap(c =>
                    this.channelsService.getDisasterRecoveryState(c) === RecoveryState.alternative
                        ? c.failover
                            ? [c.deliveryChannel.primary_broadcaster_cluster_id]
                            : [c.primary_broadcaster_cluster_id]
                        : []
                )
            )
        );
        channels.map(channel => {
            channel._frontData.primaryCluster = clusters.find(
                c => c.id === (channel.failover ? channel.deliveryChannel : channel).primary_broadcaster_cluster_id
            );
        });

        this.channels = channels.map(c => {
            return { ...c };
        });
    }

    private async handleTargetObjects(targetObjects: { id: number; type: TargetApiType }[]) {
        const promises = [];
        for (const object of targetObjects) {
            promises.push(firstValueFrom(this.ts.refreshTarget(object.type, object.id)));
        }
        const targets = await Promise.all(promises);
        targets.map(target => (target._frontData = {}));

        const channels = await this.fetchChannels(
            targets
                .filter(t => this.ts.getDisasterRecoveryState(t) === RecoveryState.alternative)
                .map(t => ({
                    id: t.primary_channel_id,
                    type: t.adaptiveChannel
                        ? "adaptive_channel"
                        : t.deliveryChannel.failover
                        ? "failover_channel"
                        : "delivery_channel"
                }))
        );

        targets.map(target => {
            target._frontData.primaryChannel = channels.find(c => c.id === target.primary_channel_id);
        });

        this.targets = targets.map(t => {
            return { ...t };
        });
    }

    async submit() {
        this.resetSelecteditems(true);
        this.toggleInputs(true);
        const rows = [...this.selectedSourcesRows, ...this.selectedChannelRows, ...this.selectedTargetRows];

        for (const row of rows) {
            if (row._frontData.rowSuccess === true) continue;
            if (row._frontData.error) continue;

            row._frontData.rowLoading = true;
            this.refreshTable();

            const objectType = this.objects.find(o => o.id === row.id).type;
            if (objectType === "source") {
                const source = row as Source;
                this.ss.toggleDisasterRecoveryState(source).then(result => {
                    source.disableRow = !!result;
                    source._frontData.rowSuccess = !!result;
                    source._frontData.rowLoading = false;
                    if (result) this.deselectRow(source, "source");
                    this.refreshTable();
                });
            } else if (
                objectType === "adaptive_channel" ||
                objectType === "delivery_channel" ||
                objectType === "failover_channel"
            ) {
                const channel = row as DeliveryChannel | AdaptiveChannel | FailoverChannel;
                this.channelsService
                    .toggleDisasterRecoveryState(
                        channel,
                        channel.failover
                            ? channel.deliveryChannel.target_broadcaster_id
                            : channel.delivery
                            ? channel.target_broadcaster_id
                            : (channel as AdaptiveChannel).broadcaster_id
                    )
                    .then(result => {
                        channel.disableRow = !!result;
                        channel._frontData.rowSuccess = !!result;
                        channel._frontData.rowLoading = false;
                        if (result) this.deselectRow(channel, objectType);
                        this.refreshTable();
                    });
            } else if (this.supportedTargetTypes.includes(objectType)) {
                const target = row as TargetPidMappingTypes;
                this.ts
                    .toggleDisasterRecoveryState(
                        target,
                        target.adaptiveChannel || target.deliveryChannel.failoverChannel
                            ? null
                            : target._frontData.selected_prefered_source
                    )
                    .then(result => {
                        target.disableRow = !!result;
                        target._frontData.rowSuccess = !!result;
                        target._frontData.rowLoading = false;
                        if (result) this.deselectRow(target, objectType);
                        this.refreshTable();
                    });
            }
        }
    }

    selectedRowsChange(selectedRows) {
        this.selectedSourcesRows = selectedRows;
    }

    private refreshTable() {
        this.sources = this.sources.map(s => {
            return { ...s };
        });
        this.channels = this.channels.map(c => {
            return { ...c };
        });
        this.targets = this.targets.map(t => {
            return { ...t };
        });

        this.refreshDisabledRows();
    }

    private resetItems() {
        this.sources.map(source => {
            source._frontData.rowSuccess = null;
            source._frontData.rowLoading = false;
            source.disableRow = this.ss.getDisasterRecoveryState(source) === RecoveryState.none;
        });
        this.channels.map(channel => {
            channel._frontData.rowSuccess = null;
            channel._frontData.rowLoading = false;
            channel.disableRow = this.channelsService.getDisasterRecoveryState(channel) === RecoveryState.none;
        });
        this.targets.map(target => {
            target._frontData.rowSuccess = null;
            target._frontData.rowLoading = false;
            target.disableRow = this.ts.getDisasterRecoveryState(target) === RecoveryState.none;
        });
    }

    private resetSelecteditems(resetFailedOnly = false) {
        this.selectedSourcesRows
            .filter(s => (resetFailedOnly ? s._frontData.rowSuccess === false : true))
            .map(source => {
                source._frontData.rowSuccess = null;
            });
        this.selectedChannelRows
            .filter(c => (resetFailedOnly ? c._frontData.rowSuccess === false : true))
            .map(channel => (channel._frontData.rowSuccess = null));
        this.selectedTargetRows
            .filter(t => (resetFailedOnly ? t._frontData.rowSuccess === false : true))
            .map(target => (target._frontData.rowSuccess = null));
    }

    private toggleInputs(toggle: boolean) {
        this.disableInputs = toggle;

        this.sources
            .filter(source => this.ss.getDisasterRecoveryState(source) !== RecoveryState.none)
            .map(source => {
                source.disableRow = toggle;
            });
        this.channels
            .filter(channel => this.channelsService.getDisasterRecoveryState(channel) !== RecoveryState.none)
            .map(channel => {
                channel.disableRow = toggle;
            });
        this.targets
            .filter(target => this.ts.getDisasterRecoveryState(target) !== RecoveryState.none)
            .map(target => {
                target.disableRow = toggle;
            });
    }

    private refreshDisabledRows() {
        this.sources.map(source => {
            source.disableRow =
                this.ss.getDisasterRecoveryState(source) === RecoveryState.none ||
                source._frontData.rowSuccess === true ||
                source._frontData.rowLoading === true;
        });
        this.channels.map(channel => {
            channel.disableRow =
                this.channelsService.getDisasterRecoveryState(channel) === RecoveryState.none ||
                channel._frontData.rowSuccess === true ||
                channel._frontData.rowLoading === true;
        });
        this.targets.map(target => {
            target.disableRow =
                this.ts.getDisasterRecoveryState(target) === RecoveryState.none ||
                target._frontData.rowSuccess === true ||
                target._frontData.rowLoading === true;
        });

        this.disableInputs = !!(
            this.targets.find(t => t._frontData.rowLoading === true) ||
            this.channels.find(c => c._frontData.rowLoading === true) ||
            this.sources.find(s => s._frontData.rowLoading === true)
        );
    }

    private selectAll() {
        this.selectedSourcesRows = [
            ...this.sources.filter(source => this.ss.getDisasterRecoveryState(source) !== RecoveryState.none)
        ];
        this.selectedChannelRows = [
            ...this.channels.filter(
                channel => this.channelsService.getDisasterRecoveryState(channel) !== RecoveryState.none
            )
        ];
        this.selectedTargetRows = [
            ...this.targets.filter(target => this.ts.getDisasterRecoveryState(target) !== RecoveryState.none)
        ];
    }

    private deselectRow(row, type: SupportedObjectTypes) {
        if (type === "source") this.selectedSourcesRows = this.selectedSourcesRows.filter(s => s.id !== row.id);
        else if (this.supportedChannelTypes.includes(type))
            this.selectedChannelRows = this.selectedChannelRows.filter(c => c.id !== row.id);
        else if (this.supportedTargetTypes.includes(type))
            this.selectedTargetRows = this.selectedTargetRows.filter(t => t.id !== row.id);
    }

    sourcesTableColumnsSchema: TableSchema<Source>[] = [
        {
            header: this.translateService.instant("SOURCE"),
            columnDef: "source",
            visible: true,
            hideFromColumnChooser: true,
            component: ZxSourceComponent,
            assignComponentsInputs: (compRef: ComponentRef<ZxSourceComponent>, row) => {
                const statusComponentInstance = compRef.instance;
                statusComponentInstance.model = row;
                statusComponentInstance.showLink = true;
                statusComponentInstance.showCluster = false;
            }
        },
        {
            header: `${this.translateService.instant("FROM")} ${this.translateService.instant("CLUSTER")}`,
            columnDef: "from_cluster",
            visible: true,
            hideFromColumnChooser: true,
            component: ZxClusterComponent,
            assignComponentsInputs(compRef: ComponentRef<ZxClusterComponent>, row, searchTerm) {
                const compInstance = compRef.instance;
                compInstance.model = row.inputCluster;
                compInstance.showLink = true;
            }
        },
        {
            header: `${this.translateService.instant("TO")} ${this.translateService.instant("CLUSTER")}`,
            columnDef: "to_cluster",
            visible: true,
            hideFromColumnChooser: true,
            component: ZxClusterComponent,
            assignComponentsInputs: function (compRef: ComponentRef<ZxClusterComponent>, row, searchTerm) {
                const compInstance = compRef.instance;
                compInstance.model =
                    this.ss.getDisasterRecoveryState(row) === RecoveryState.primary
                        ? row.inputCluster.altCluster
                        : row._frontData.primaryCluster;
                compInstance.showLink = true;
            }.bind(this),
            rowIf: row => this.ss.getDisasterRecoveryState(row) !== RecoveryState.none
        },
        {
            header: this.translateService.instant("TARGET_BROADCASTER_S"),
            columnDef: "target_broadcaster",
            visible: true,
            hideFromColumnChooser: true,
            component: TargetBroadcasterSelectionComponent,
            assignComponentsInputs: function (
                compRef: ComponentRef<TargetBroadcasterSelectionComponent>,
                row,
                searchTerm
            ) {
                const compInstance = compRef.instance;
                const drState = this.ss.getDisasterRecoveryState(row);
                if (drState === RecoveryState.primary) {
                    compInstance.clusterId = row.inputCluster.altCluster.id;
                    compInstance.targetBroadcasterId = row.target_broadcaster_id < 0 ? row.target_broadcaster_id : null;
                } else if (drState === RecoveryState.alternative) {
                    compInstance.clusterId = row.primary_broadcaster_cluster_id;
                    compInstance.targetBroadcasterId = row.primary_target_broadcaster_id;
                    compInstance.disable = true; // return to primary_* fields
                }
                compInstance.specificBroadcasterOnly = this.broadcastersOnlySourceTypes.includes(row.type);
                row._frontData.error = row._frontData.error !== false ? true : false; // error until the target broadcaster is selected
                compInstance.targetBroadcasterIdChange.subscribe(targetBxId => {
                    if (targetBxId) {
                        row.target_broadcaster_id = targetBxId;
                        row._frontData.error = false;
                    }
                });
            }.bind(this),
            rowIf: row => this.ss.getDisasterRecoveryState(row) !== RecoveryState.none
        },
        {
            header: this.translateService.instant("LOADING"),
            columnDef: "rowLoading",
            visible: true,
            hideFromColumnChooser: true,
            hideColumnName: true,
            width: 70,
            component: SpinnerAnimationInlineComponent,
            assignComponentsInputs: (compRef: ComponentRef<SpinnerAnimationInlineComponent>, row) => {
                const statusComponentInstance = compRef.instance;
                statusComponentInstance.spinner = row._frontData.rowLoading;
                statusComponentInstance.placeholderText =
                    row._frontData.rowSuccess === true
                        ? this.translateService.instant("SUCCESS")
                        : row._frontData.rowSuccess === false
                        ? this.translateService.instant("FAILED")
                        : "";
            }
        }
    ];

    channelsTableColumnsSchema: TableSchema<FailoverChannel | AdaptiveChannel | DeliveryChannel>[] = [
        {
            header: this.translateService.instant("CHANNEL"),
            columnDef: "channel",
            visible: true,
            hideFromColumnChooser: true,
            component: DialogObjectComponent,
            assignComponentsInputs: (compRef: ComponentRef<DialogObjectComponent>, row) => {
                const statusComponentInstance = compRef.instance;
                statusComponentInstance.object = row;
                statusComponentInstance.type = "CHANNEL";
            }
        },
        {
            header: `${this.translateService.instant("FROM")} ${this.translateService.instant("CLUSTER")}`,
            columnDef: "from_cluster",
            visible: true,
            hideFromColumnChooser: true,
            component: ZxClusterComponent,
            assignComponentsInputs(compRef: ComponentRef<ZxClusterComponent>, row, searchTerm) {
                const compInstance = compRef.instance;
                if (row.type === "failover_channel") {
                    compInstance.model = row.deliveryChannel.processingCluster;
                } else {
                    compInstance.model = row.processingCluster;
                }
                compInstance.showLink = true;
            }
        },
        {
            header: `${this.translateService.instant("TO")} ${this.translateService.instant("CLUSTER")}`,
            columnDef: "to_cluster",
            visible: true,
            hideFromColumnChooser: true,
            component: ZxClusterComponent,
            assignComponentsInputs: function (
                compRef: ComponentRef<ZxClusterComponent>,
                row: DeliveryChannel | AdaptiveChannel | FailoverChannel,
                searchTerm
            ) {
                const compInstance = compRef.instance;
                compInstance.model =
                    this.channelsService.getDisasterRecoveryState(row) === RecoveryState.primary
                        ? row.failover
                            ? row.deliveryChannel.processingCluster.altCluster
                            : row.processingCluster.altCluster
                        : row._frontData.primaryCluster;
                compInstance.showLink = true;
            }.bind(this),
            rowIf: row => this.channelsService.getDisasterRecoveryState(row) !== RecoveryState.none
        },
        {
            header: this.translateService.instant("TARGET_BROADCASTER_S"),
            columnDef: "target_broadcaster",
            visible: true,
            hideFromColumnChooser: true,
            component: TargetBroadcasterSelectionComponent,
            assignComponentsInputs: function (
                compRef: ComponentRef<TargetBroadcasterSelectionComponent>,
                row: DeliveryChannel | AdaptiveChannel | FailoverChannel,
                searchTerm
            ) {
                const compInstance = compRef.instance;

                const drState = this.channelsService.getDisasterRecoveryState(row);
                if (drState === RecoveryState.primary) {
                    compInstance.clusterId = row.failover
                        ? row.deliveryChannel.processingCluster.altCluster.id
                        : row.processingCluster.altCluster.id;
                    const targetBxId = row.failover
                        ? row.deliveryChannel.target_broadcaster_id
                        : row.adaptive
                        ? row.broadcaster_id
                        : (row as DeliveryChannel).target_broadcaster_id;
                    compInstance.targetBroadcasterId = targetBxId < 0 ? targetBxId : null;
                } else if (drState === RecoveryState.alternative) {
                    compInstance.clusterId = row.failover
                        ? row.deliveryChannel.primary_broadcaster_cluster_id
                        : row.primary_broadcaster_cluster_id;
                    compInstance.targetBroadcasterId = row.failover
                        ? row.deliveryChannel.primary_target_broadcaster_id
                        : (row as DeliveryChannel | AdaptiveChannel).primary_target_broadcaster_id;
                    compInstance.disable = true; // prevent changing the primary target broadcaster
                }

                row._frontData.error = row._frontData.error !== false ? true : false; // error until a target broadcaster is selected
                compInstance.targetBroadcasterIdChange.subscribe(targetBxId => {
                    if (typeof targetBxId === "number") {
                        if (row.failover) row.deliveryChannel.target_broadcaster_id = targetBxId;
                        else if (row.delivery) row.target_broadcaster_id = targetBxId;
                        else (row as AdaptiveChannel).broadcaster_id = targetBxId;
                        row._frontData.error = false;
                    }
                });
            }.bind(this),
            rowIf: row => this.channelsService.getDisasterRecoveryState(row) !== RecoveryState.none
        },
        {
            header: this.translateService.instant("LOADING"),
            columnDef: "rowLoading",
            visible: true,
            hideFromColumnChooser: true,
            hideColumnName: true,
            width: 70,
            component: SpinnerAnimationInlineComponent,
            assignComponentsInputs: (compRef: ComponentRef<SpinnerAnimationInlineComponent>, row) => {
                const statusComponentInstance = compRef.instance;
                statusComponentInstance.spinner = row._frontData.rowLoading;
                statusComponentInstance.placeholderText =
                    row._frontData.rowSuccess === true
                        ? this.translateService.instant("SUCCESS")
                        : row._frontData.rowSuccess === false
                        ? this.translateService.instant("FAILED")
                        : "";
            }
        }
    ];

    targetsTableColumnsSchema: TableSchema<TargetObjectType>[] = [
        {
            header: this.translateService.instant("TARGET"),
            columnDef: "target",
            visible: true,
            hideFromColumnChooser: true,
            component: ZxTargetComponent,
            assignComponentsInputs: (compRef: ComponentRef<ZxTargetComponent>, row, searchTerm) => {
                const targetComponentInstance = compRef.instance;
                targetComponentInstance.model = row;
                targetComponentInstance.showLink = true;
            }
        },
        {
            header: `${this.translateService.instant("FROM")} ${this.translateService.instant("CHANNEL")}`,
            columnDef: "from_channel",
            visible: true,
            hideFromColumnChooser: true,
            component: ZxTargetChannelColumnComponent,
            assignComponentsInputs: assignComponentsTargetChannelsColumnAdapter
        },
        {
            header: `${this.translateService.instant("TO")} ${this.translateService.instant("CHANNEL")}`,
            columnDef: "to_channel",
            visible: true,
            hideFromColumnChooser: true,
            component: DialogObjectComponent,
            assignComponentsInputs: (compRef: ComponentRef<DialogObjectComponent>, row) => {
                const compInstance = compRef.instance;
                compInstance.type = "CHANNEL";
                compInstance.object = this.getTargetRowToChannel(row);
            },
            rowIf: row =>
                this.ts.getDisasterRecoveryState(row) !== RecoveryState.none && this.getTargetRowToChannel(row) !== null
        },
        {
            header: this.translateService.instant("SOURCE_PREFERENCE"),
            columnDef: "source_perference",
            visible: true,
            hideFromColumnChooser: true,
            component: ZxDeliveryChannelSourceSelectComponent,
            assignComponentsInputs: function (
                compRef: ComponentRef<ZxDeliveryChannelSourceSelectComponent>,
                row: TargetObjectType,
                searchTerm
            ) {
                const drState = this.ts.getDisasterRecoveryState(row);
                const compInstance = compRef.instance;
                compInstance.id = "preferred_source";
                compInstance.name = "preferred_source";
                compInstance.appendToBody = true;

                if (row._frontData.rowLoading || row._frontData.rowSuccess) compInstance.disabled = true;

                if (row.deliveryChannel && !row.deliveryChannel.failover_channel_id) {
                    compInstance.channel_id = this.getTargetRowToChannel(row)?.id;
                    if (drState === RecoveryState.alternative) {
                        if (typeof (row as any).primary_source_id === "number") {
                            compInstance.model = (row as any).primary_source_id;
                            compInstance.disabled = true;
                        }
                    }
                    compInstance.modelChange.subscribe(targetSrc => {
                        row._frontData.selected_prefered_source = targetSrc;
                    });
                } else {
                    compInstance.model = SourcePreferenceSelectionValues.invalid;
                    compInstance.disabled = true;
                }
            }.bind(this),
            rowIf: row => this.ts.getDisasterRecoveryState(row) !== RecoveryState.none
        },
        {
            header: this.translateService.instant("LOADING"),
            columnDef: "rowLoading",
            visible: true,
            hideFromColumnChooser: true,
            hideColumnName: true,
            width: 70,
            component: SpinnerAnimationInlineComponent,
            assignComponentsInputs: (compRef: ComponentRef<SpinnerAnimationInlineComponent>, row) => {
                const statusComponentInstance = compRef.instance;
                statusComponentInstance.spinner = row._frontData.rowLoading;
                statusComponentInstance.placeholderText =
                    row._frontData.rowSuccess === true
                        ? this.translateService.instant("SUCCESS")
                        : row._frontData.rowSuccess === false
                        ? this.translateService.instant("FAILED")
                        : "";
            }
        }
    ];

    private getTargetRowToChannel(row: TargetObjectType) {
        const drState = this.ts.getDisasterRecoveryState(row);
        if (drState === RecoveryState.primary) {
            if (row.adaptiveChannel?.altChannel) {
                row.adaptiveChannel.altChannel.processingCluster = row.adaptiveChannel.processingCluster;
                return row.adaptiveChannel.altChannel;
            } else if (row.deliveryChannel) {
                if (row.deliveryChannel.failoverChannel?.altChannel) {
                    row.deliveryChannel.failoverChannel.altChannel.processingCluster =
                        row.deliveryChannel.processingCluster;
                    return row.deliveryChannel.failoverChannel.altChannel;
                } else if (row.deliveryChannel.altChannel) {
                    row.deliveryChannel.altChannel.processingCluster = row.deliveryChannel.processingCluster;
                    return row.deliveryChannel.altChannel;
                }
            }
        } else if (drState === RecoveryState.alternative) {
            if (row._frontData.primaryChannel) return row._frontData.primaryChannel;
        }
        return null;
    }

    private getTargetRowCurrentChannel(row: TargetObjectType) {
        if (row.adaptiveChannel) {
            return row.adaptiveChannel;
        } else if (row.deliveryChannel) {
            if (row.deliveryChannel.failoverChannel) {
                return row.deliveryChannel.failoverChannel;
            } else if (row.deliveryChannel) return row.deliveryChannel;
        }
        return null;
    }
}
